import PNTrading from 'src/pn-trading-sdk';
import { getSettings } from 'src/pn-trading-sdk/PNConfig';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export default function FileSelector({ path, defaultFile, onChange = () => {}, allowRemove = true }) {
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState();

	useEffect(async () => {
		if (defaultFile) {
			setValue(await PNTrading.get(`/files/${defaultFile}`));
		}
	}, [defaultFile]);

	const remove = async () => {
		onChange(undefined);
		setValue(undefined);
	};

	const upload = async (e) => {
		e.preventDefault();
		setLoading(true);
		let data = new FormData(e.target);

		let resp = await (
			await fetch(`${getSettings().PN_SDK_REST_URL}/files${path != undefined ? `?path=${path}/${data.get('file').name}` : ''}`, {
				method: 'POST',
				body: data,
				headers: {
					authorization: `Bearer ${localStorage.getItem('user')}`,
				},
			})
		).json();

		onChange(resp);
		setValue(resp);
		setLoading(false);
	};

	return (
		<Style>
			{!value && (
				<>
					<form onSubmit={upload}>
						<div className="wrapper">
							<input required type="file" name="file" />
						</div>
						<button className={`${loading ? 'loading' : ''}`} type="submit">
							Ladda upp
						</button>
					</form>
				</>
			)}

			{/* File is set. */}
			{value && (
				<>
					<div className="wrapper">
						<a href={value.url} target="_blank" rel="noopener noreferrer">
							{value.name} (Ladda ner)
						</a>
					</div>
					{allowRemove && <button className={`${loading ? 'loading' : ''}`} onClick={remove}>
						Ta bort
					</button>}
				</>
			)}
		</Style>
	);
}

const Style = styled.div`
	display: inline-flex;
	width: 100%;

	form {
		display: inline-flex;
		width: 100%;
	}

	.wrapper {
		font-family: 'Roboto', sans-serif;
		font-size: 0.85em;
		border-radius: 0.35em;
		border: solid 1px rgb(199, 199, 199);
		box-sizing: border-box;
		display: inline-flex;
		width: 100%;
		padding: 0.75em 1em;

		input {
			width: 100%;
			padding: 0;
			border: none;
		}
	}

	button {
		flex-shrink: 0;
		margin-left: 1rem;
	}
`;
