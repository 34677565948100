import React, { useEffect, useState } from 'react';
import Table from '../components/Table';
import Page from '../layout/Page';
import Card from '../layout/Card';
import Icon from '../elements/Icon';
import usePopup from '../utils/hooks/usePopup';
import DeleteObject from '../layout/popups/DeleteObject';
import styled from 'styled-components';
import { formatPrice, isObjectEnded } from '../utils/Util';
import PNTrading from 'src/pn-trading-sdk';
import useBids from '../utils/bids/useBids';
import useAutoBids from '../utils/bids/useAutoBids';
import useSessionState from '../utils/hooks/useSessionState';
import { Objects } from 'src/pn-trading-sdk/Objects';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function Bidding({ object }) {
	const bids = useBids(object);
	const maxBids = useAutoBids(object);
	let leading = bids?.[0];
	const leadingMax = maxBids?.[0];
	const ended = isObjectEnded(object);

	if (object.ending_data) {
		leading = object.ending_data.lastBid;
	}

	if (!leading) return <label style={{ color: 'orange' }}>Inga bud</label>;

	return (
		<div className="bidding">
			<div className="leading-bid" title={JSON.stringify(leading, null, 4)}>
				<label>{ended ? 'Vinnande bud:' : 'Ledande bud:'}</label>
				<span>{formatPrice(leading.amount)}</span>
			</div>

			<div className="leading-bid" title={JSON.stringify(leadingMax, null, 4)}>
				<label>Maxbud:</label>
				<span>{leadingMax ? formatPrice(leadingMax.amount) : '-'}</span>
			</div>
		</div>
	);
}

let initialLoad = true;

export default function PageObjects() {
	const limit = 30;

	const history = useHistory();
	const [open] = usePopup();

	const [searchString, setSearchString] = useSessionState('_pn_objects_search_string', ''); // String value, updated when input field is changed.
	const [filters, setFilters] = useState({}); // Filters object, update from table.
	const [page, setPage] = useSessionState('_pn_objects_table_page', 0); // Current page to load, updated from table.
	const [objects, setObjects] = useState([]); // Full object response from API.
	const [totalItems, setTotalItems] = useState(); // Total items, only loaded on initialization, excluding filters.

	const [status, setStatus] = useSessionState('_pn_objects_status', 'all');
	const [selectedAuction, setSelectedAuction] = useSessionState('_pn_objects_selected_auction', undefined);

	const [auctions, setAuctions] = useState(); // Load auctions to match against objects in table.

	const isLoading = auctions == undefined || totalItems == undefined;

	const load = async () => {
		let _filters = { ...filters };

		if (!isNaN(searchString)) {
			_filters.whitelist = searchString;
		} else {
			_filters.title = searchString;
		}

		let objects = await Objects.list(
			{
				page,
				limit,
				auction: selectedAuction,
				ending: {
					all: undefined,
					ended: 'ENDED',
					active: new Date().toISOString(),
				}[status],
				orderBy: 'position',
				order: 'ASC',
			},
			`&admin=true${Object.keys(_filters).length != 0 ? '&' : ''}${Object.keys(_filters)
				.map((key) => `${key}=${_filters[key]}`)
				.join('&')}`
		);
		initialLoad = false;
		setObjects(objects);
	};

	useEffect(() => load(), [page]); // Reload items when page changes.

	useEffect(() => {
		if (initialLoad) return;
		setSearchString('');
		setPage(0);
		load();
	}, [status, selectedAuction]);

	useEffect(() => {
		Objects.list().then((obs) => setTotalItems(obs.total_items)); // Load total items.
		PNTrading.get(`/auctions?&noCache=true`).then(setAuctions); // Load auctions.
		return () => initialLoad = true;
	}, []);

	const search = (e) => {
		e.preventDefault();
		load();
	};

	if (isLoading)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="small" className={`key-values`}>
				<h4>Antal objekt:</h4>
				<div className="spacer s"></div>
				<h1>{totalItems ?? '-'} st</h1>
			</Card>

			<Card size="full">
				<div className="header">
					<h1>Alla objekt</h1>
					<div className="spacer auto"></div>

					<select value={status} onChange={(e) => setStatus(e.target.value)}>
						<option value="all">Välj status...</option>
						<option value="active">Aktiva objekt</option>
						<option value="ended">Avslutade objekt</option>
					</select>

					<div className="spacer ws"></div>

					<select value={selectedAuction} onChange={(e) => setSelectedAuction(e.target.value)}>
						<option value="undefined">Välj auktion...</option>
						{auctions?.sort((a, b) => b.id - a.id).map((a) => (
							<option key={a.id} value={a.id} disabled={(status == 'active' && a.status == 'ended') || (status == 'ended' && a.status == 'active') || a.status == 'empty'}>
								{a.id} - {a.name}
								{a.status == 'empty' ? ' (inga aktiva objekt)' : ''}
							</option>
						))}
					</select>

					<div className="spacer wl"></div>

					<form onSubmit={search}>
						<input type="text" placeholder="Sök objekt" value={searchString} onChange={(e) => setSearchString(e.target.value)} />
						<div className="spacer ws"></div>
						<button type="submit">Sök</button>
					</form>
				</div>

				<div className="spacer m"></div>

				<Table
					pages={objects ? objects.pages : 1}
					defaultPage={page}
					onPageChange={setPage}
					load={() => {}}
					limit={limit}
					tableID={'_pn_objects_table'}
					data={objects ? objects.data : []}
					structure={[
						{
							heading: 'Position / ID',
							key: 'id',
							component: (row) => (
								<>
									{row.position} / {row.id}
								</>
							),
						},
						{
							heading: 'Modell',
							key: 'title',
							// sortable: true,
							// filter: 'text',
							component: (row) => {
								let text = row.title;
								return <div title={JSON.stringify(row, null, 4)}>{text.length > 30 ? text.substring(0, 30) + '...' : text}</div>;
							},
						},
						{
							heading: 'Auktion',
							key: 'auction',
							// sortable: true,
							// filter: 'text',
							component: (row) => {
								let auction = auctions?.find((a) => a.id == row.auction);
								let text = auction ? auction.name : '-';
								return <>{text.length > 30 ? text.substring(0, 30) + '...' : text}</>;
							},
						},
						{
							heading: 'Budgivning',
							component: (row) => {
								return <Bidding object={row} />;
							},
						},
						{
							heading: 'Status',
							component: (object) => {
								return (
									<>
										{isObjectEnded(object) && <div style={{ color: '#999' }}>Avslutad</div>}
										{!isObjectEnded(object) && <div style={{ color: 'green' }}>Pågående</div>}
									</>
								);
							},
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/objects/${row.id}`)}>edit</Icon>
										<Icon onClick={() => open(<DeleteObject id={row.id} />)}>delete</Icon>
									</div>
								);
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.bidding {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;

		.leading-bid {
			display: flex;
			flex-direction: column;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
