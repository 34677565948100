import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Invoices, { SellableObject, UseInvoiceInvoice } from 'src/utils/api/Invoices';
import { useClosePopup } from 'src/utils/hooks/usePopup';
import Popup from 'src/layout/Popup';
import Button from 'src/elements/Button';
import Table from 'src/components/Table';
import { Link } from 'react-router-dom';
import { formatPrice } from 'src/utils/Util';

export default function AddObjectPopup({ invoice }: { invoice: UseInvoiceInvoice }) {
	const close = useClosePopup();

	const [sellableObjects, setSellableObjects] = useState<null | SellableObject[]>(null);
	const searchDebounce = useRef<any>(0);
	const [loading, setLoading] = useState<null | number>(null);

	const search = useCallback(async (search = "") => {
		const objects = await Invoices.getSellableObjects({ search });
		setSellableObjects(objects);
	}, []);

	useEffect(() => {
		search();
	}, [ search ]);

	const handleAddObject = (object: SellableObject) => {
		return async () => {
			setLoading(object.id);
			await invoice.addObject(object.id);
			setLoading(null);
			close();
		}
	};

	const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		searchDebounce.current && clearTimeout(searchDebounce.current);
		searchDebounce.current = setTimeout(() => search(e.target.value), 300);
	};

	return (
		<Popup close={close} width="90%" maxWidth="60em">
			<Style>
				<h2 className="title">Lägg till objekt i ordern</h2>
				<div className="spacer s"></div>
				<p>
					Välj ett objekt från listan och klicka på "Lägg till" för att lägga till objektet i ordern.
				</p>

				<div className="spacer m"></div>

				<input type="text" placeholder="Sök objekt" onChange={handleSearchOnChange} style={{ width: '100%' }} />

				<div className="spacer s"></div>

				<Table
					data={sellableObjects ?? []}
					limit={8}
					structure={[
						{
							key: 'thumbnail',
							heading: '',
							component: (object: SellableObject) => object.thumbnail ? <img src={object.thumbnail} alt="" style={{
								width: '50px',
								height: '50px',
								objectFit: 'cover',
								borderRadius: '5px',
							}} /> : null
						},
						{
							key: 'title',
							heading: 'Objekt',
							component: (object: SellableObject) => <div style={{
								display: 'flex',
								flexDirection: 'column',
							}}>

								<div style={{
									fontSize: '0.8em',
								}}>{object.id}</div>

								<Link 
									target="_blank"
									to={`/objects/${object.id}`}
									style={{
										display: 'inline-block',
										maxWidth: '200px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										color: 'black',
									}}
								>{object.title}</Link>
							</div>
						},
						{
							key: 'auction_name',
							heading: 'Auktion',
							component: (object: SellableObject) => <div style={{
								display: 'inline-block',
								maxWidth: '200px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}>({object.auction}) {object.auction_name}</div>
						},
						{
							key: 'leading_bid',
							heading: 'Vunnet bud',
							component: (object: SellableObject) => <div>
								{object.leading_bid ? formatPrice(object.leading_bid) : '-'}
							</div>
						},
						{
							key: 'actions',
							heading: '',
							cellStyle: {
							},
							component: (object: SellableObject) => <Button onClick={handleAddObject(object)} className={loading === object.id ? 'loading' : ''}>Lägg till</Button>
						},
					]}
				/>

				<div className="spacer s"></div>

				<div className="buttons">
					<Button type="secondary outlined" onClick={close}>
						Avbryt
					</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
