import React, { useEffect, useRef, useState } from 'react';
import Page from '../layout/Page';
import Card from '../layout/Card';
import Icon from '../elements/Icon';
import usePopup, { useClosePopup } from '../utils/hooks/usePopup';
import MediaLibrary from '../layout/popups/MediaLibrary';
import MediaLibraryPane from '../layout/MediaLibraryPane';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../elements/Button';
import Images from '../utils/api/Images';
import Popup from '../layout/Popup';
import PNTrading from 'src/pn-trading-sdk';
import BiddingPanel from './object/BiddingPanel';
import Select, { Option } from '../elements/Select';
import FileSelector from '../elements/FileSelector';
import { useSellers } from '../utils/api/Sellers';
import CategorySelect from '../components/CategorySelect';
import DescriptionInput from '../components/object/DescriptionInput';
import Gallery from './object/Gallery';

export default function PageObject(props) {
	let id = props.match.params.id;

	const { sellers } = useSellers();

	const close = useClosePopup();
	const [object, setObject] = useState();
	const [minimumPrice, setMinimumPrice] = useState(0);
	const [thumbnail, setThumbnail] = useState();
	const [gallery, setGallery] = useState([]);

	const [temp, setTemp] = useState({});
	const [edit, setEdit] = useState({});

	const [metadata, setMetadata] = useState();
	const prevMetadata = useRef(metadata);
	const minimumPriceDebounce = useRef();

	const [open] = usePopup();

	const loadGallery = async (gallery) => {
		let images = (
			await Images.list({
				ids: gallery,
			})
		).data;

		setGallery(gallery.map((id) => images.find((i) => i.id == id)));
	};

	const updateGallery = async (gallery) => {
		setObject((object) => ({ ...object, gallery: gallery }));
		await loadGallery(gallery);
		await PNTrading.put(`/objects/${id}`, {
			gallery: JSON.stringify(gallery),
		});
	};

	const addMetaIfEmpty = (meta, key) => {
		if (!meta.find((m) => m[0] == key)) meta.push([key, '']);
	};

	/**
	 * Called from select input on change.
	 */
	const updateCategory = async (e) => {
		// Vehicle category ID's. TODO - NOT HARDCODE THIS....
		if ([27, 28, 29, 30, 31, 32, 33, 34].includes(parseInt(e.target.value))) {
			setMetadata((meta) => {
				let newMeta = [...meta];

				addMetaIfEmpty(newMeta, 'Marknadsvärde');
				addMetaIfEmpty(newMeta, 'Antal tidigare ägare');
				addMetaIfEmpty(newMeta, 'Registreringsnummer');
				addMetaIfEmpty(newMeta, 'Modellår');
				addMetaIfEmpty(newMeta, 'Tillverkningsmånad');
				addMetaIfEmpty(newMeta, 'Mätarställning');
				addMetaIfEmpty(newMeta, 'Första datum i trafik');
				addMetaIfEmpty(newMeta, 'Servicehistorik');
				addMetaIfEmpty(newMeta, 'Servicebok');
				addMetaIfEmpty(newMeta, 'Växellåda');
				addMetaIfEmpty(newMeta, 'Antal säten');
				addMetaIfEmpty(newMeta, 'Antal nycklar');
				addMetaIfEmpty(newMeta, 'Färg');
				addMetaIfEmpty(newMeta, 'Lack');
				addMetaIfEmpty(newMeta, 'Klädsel');
				addMetaIfEmpty(newMeta, 'Tankvolym');
				addMetaIfEmpty(newMeta, 'Skattemånader');
				addMetaIfEmpty(newMeta, 'Skatt årsavgift');

				console.log(newMeta);

				return newMeta;
			});
		}

		await PNTrading.put(`/objects/${id}`, {
			category: e.target.value,
		});
	};

	/**
	 * Called from select input on change.
	 */
	const updateShipping = async (e) => {
		if (e.target.value == 'none') return;

		await PNTrading.put(`/objects/${id}`, {
			shippable: e.target.value,
		});
	};

	/**
	 * Called from select input on change.
	 */
	const updateTaxStatus = async (e) => {
		await PNTrading.put(`/objects/${id}`, {
			no_tax: e.target.value,
		});
	};

	/**
	 * Called from select on change.
	 */
	const updateSeller = async (e) => {
		await PNTrading.put(`/objects/${id}`, {
			seller: e.value,
		});
	};

	function minimumPriceChange(e) {
		clearTimeout(minimumPriceDebounce.current);
		setMinimumPrice(e.target.value);

		minimumPriceDebounce.current = setTimeout(async () => {
			console.log(e.target.value)
			await PNTrading.post(`/objects/${id}/minimum`, {
				minimum_price: e.target.value,
			});
		}, 500);
	}

	useEffect(() => {
		let a = setTimeout(async () => {
			let originalMeta = prevMetadata.current ? prevMetadata.current.map((m) => Object.values(m)) : [];
			let newMeta = metadata ? metadata.map((m) => Object.values(m)) : [];

			// Check for new meta keys.
			for (let meta of newMeta) {
				// If true, this meta key is not found in the original data.
				if (originalMeta.find((om) => om[0].trim() == meta[0].trim()) == undefined) {
					await PNTrading.request(
						`/objects/meta/${id}`,
						{
							meta_key: meta[0],
						},
						{
							method: 'DELETE',
						}
					);

					PNTrading.post(`/objects/meta/${id}`, {
						meta_key: meta[0],
						value: meta[1],
					});

					continue;
				}

				// If true, this meta value has changed.
				if (originalMeta.find((om) => om[0].trim() == meta[0].trim() && om[1].trim() == meta[1].trim()) == undefined) {
					PNTrading.put(`/objects/meta/${id}`, {
						meta_key: meta[0],
						value: meta[1],
					});

					continue;
				}
			}

			// Check for removed meta keys.
			for (let meta of originalMeta) {
				// If true, this meta key is not found in the new data and should be removed.
				if (newMeta.find((nm) => nm[0].trim() == meta[0].trim()) == undefined) {
					await PNTrading.request(
						`/objects/meta/${id}`,
						{
							meta_key: meta[0],
						},
						{
							method: 'DELETE',
						}
					);

					continue;
				}
			}

			prevMetadata.current = metadata;
		}, 2000);
		return () => clearTimeout(a);
	}, [id, metadata, object]);

	useEffect(async () => {
		let changes = {};

		console.log(edit, temp)

		if (edit.title === false) {
			if (temp.title != undefined) changes.title = temp.title;
			setEdit((e) => ({ ...e, title: undefined }));
		}

		if (edit.description === false) {
			if (temp.description != undefined) changes.description = temp.description;
			setEdit((e) => ({ ...e, description: undefined }));
		}

		if (edit.metaDescription === false) {
			if (temp.metaDescription != undefined) changes.metaDescription = temp.metaDescription;
			setEdit((e) => ({ ...e, metaDescription: undefined }));
		}

		if (Object.keys(changes).length == 0) return;

		let resp = await PNTrading.put(`/objects/${id}`, changes);
		console.log('Update response:', resp);
	}, [edit]);

	useEffect(() => {
		(async () => {
			let object = await PNTrading.get(`/objects/${id}?embed=true&noCache=true`);

			prevMetadata.current = object.meta ? Object.entries(object.meta) : [];
			setMetadata(Object.entries(object.meta ? object.meta : {}));

			if (object.thumbnail) setThumbnail(await Images.get(object.thumbnail));

			if (object?.gallery && object.gallery.length != 0) await loadGallery(JSON.parse(object.gallery));

			setObject(object);

			if (object?.has_minimum_price) {
				const minimumResp = await PNTrading.get(`/objects/${id}/minimum`);
				const minimum = minimumResp.minimum_price;
				setMinimumPrice(minimum);
			}
		})();
	}, [id]);

	const media = (id) => {
		open(
			<MediaLibrary
				defaultImage={thumbnail?.id}
				onChange={async (image) => {
					setThumbnail(image);
					await PNTrading.put(`/objects/${id}`, { thumbnail: image.id });
				}}
			/>
		);
	};

	if (!object) return <></>;

	const defaultSeller = sellers?.findIndex((c) => c.id == object?.seller);

	return (
		<Style>
			{/* Images */}
			<Card className="object-info" size="small">

				<Gallery images={gallery} />

				<div className="spacer s"></div>

				<div className="input-group">
					<label htmlFor="youtube_id">Youtube ID:</label>
					<br />
					<input
						style={{ width: '100%' }}
						type="text"
						name="youtube_id"
						id="youtube_id"
						value={metadata.find((m) => m[0] == '_youtube_id')?.[1]}
						onChange={(e) => {

							console.log(e.target.value);

							if (metadata.find((m) => m[0] == '_youtube_id') == undefined) {
								setMetadata((m) => [...m, ['_youtube_id', e.target.value]]);
							} else if (e.target.value != '') {
								setMetadata((m) => m.map((m) => m[0] == '_youtube_id' ? ['_youtube_id', e.target.value] : m));
							} else {
								setMetadata((m) => m.filter((m) => m[0] != '_youtube_id'));
							}
						}}
						placeholder='Ex: UBUNrFtufWo'
					/>
				</div>

				<div className="spacer s"></div>

				<div className="image" onClick={() => media(id)}>
					<img src={thumbnail ? thumbnail.sizes['500x500'] : 'https://via.placeholder.com/500'} />
					<Icon>insert_photo</Icon>
				</div>

				<div className="spacer s"></div>

				{object && (
					<MediaLibraryPane
						preventLoading={true}
						allowUpload={false}
						allowSelect={false}
						enablePagination={false}
						enableSidepanel={false}
						forcedHeight={false}
						columns={2}
						onClick={[
							(image, object) => {
								open(
									<Popup close={close}>
										<h2>Vill du ta bort bilden?</h2>
										<div className="spacer s"></div>
										<p>Bilden kommer tas bort från galleriet.</p>
										<div className="spacer m"></div>
										<div className="row">
											<Button type="secondary" onClick={close}>
												Nej
											</Button>
											<div className="spacer ws"></div>
											<Button
												data-length={gallery?.length}
												onClick={async () => {
													let objectGallery = object.gallery ? (typeof object.gallery == 'string' ? JSON.parse(object.gallery) : object.gallery) : [];
													await updateGallery(objectGallery.filter((i) => i != image.id));
													close();
												}}
											>
												Ja, ta bort
											</Button>
										</div>
									</Popup>
								);
							},
							object,
						]}
						images={gallery}
						appendFileElement={[
							(object) => {
								return (
									<div
										data-length={gallery?.length}
										className={`file add-new`}
										onClick={() => {
											open(
												<MediaLibrary
													onChange={async (image) => {
														let objectGallery = object.gallery ? (typeof object.gallery == 'string' ? JSON.parse(object.gallery) : object.gallery) : [];
														await updateGallery([...objectGallery, image.id]);
													}}
												/>
											);
										}}
									>
										<Icon>add</Icon>
										<h4>Lägg till bild..</h4>
									</div>
								);
							},
							object,
						]}
					/>
				)}
			</Card>

			<main>
				<div className="contained">
					{/* Title row */}
					<div className="row title-row">
						<textarea
							id="title-field"
							className="title"
							defaultValue={object.title}
							disabled={!edit.title}
							onChange={(e) => setTemp((t) => ({ ...t, title: e.target.value }))}
							onInput={(e) => {
								e.target.style.height = '';
								e.target.style.height = Math.min(e.target.scrollHeight, 200) + 'px';
							}}
						/>

						<Icon
							onClick={() => {
								if (!edit.title) {
									setTimeout(() => {
										let element = document.getElementById('title-field');
										element.focus();
										element.setSelectionRange(element.value.length, element.value.length);
									}, 0);
								}

								setEdit((e) => ({ ...e, title: !e.title }));
							}}
						>
							{edit.title ? 'save' : 'edit'}
						</Icon>
					</div>

					{/* Buttons */}
					<div className="button-row row">
						<a href={`http://riksauktioner.se/objekt/${object.id}`} className="button" target="_blank">
							Öppna objektet i nytt fönster
						</a>
						<div className="spacer ws"></div>
						<Link to={`/lists/object-summary/${object.id}`} className="button">
							Visa sammanställning
						</Link>
					</div>

					{/* Category + Meta fields. */}
					<div className="fields-grid">
						{/* Category. */}
						<div className="field">
							<label>Kategori</label>
							<div className="input">
								<CategorySelect defaultValue={object.category} onChange={updateCategory} />
							</div>
						</div>

						{/* Seller. */}
						<div className="field">
							<label>Säljare</label>
							<div className="input">
								{sellers && <Select onChange={updateSeller} defaultValue={defaultSeller == -1 ? undefined : defaultSeller}>
									{sellers.map((customer) => (
										<Option key={customer.id} value={customer.id}>{`${customer.org_number} - ${customer.name}`}</Option>
									))}
								</Select>}
							</div>
						</div>

						{/* Shipping. */}
						<div className="field">
							<label>Frakt</label>
							<div className="input">
								<select defaultValue={object.shippable} onChange={updateShipping}>
									<option value="none">Välj frakt...</option>
									<option value="YES">Ja - Kan skickas</option>
									<option value="NO">Nej - Ej fraktbar</option>
								</select>
							</div>
						</div>

						{/* Tax. */}
						<div className="field">
							<label>Momsfri</label>
							<div className="input">
								<select defaultValue={object.no_tax} onChange={updateTaxStatus}>
									<option value="NO">Nej</option>
									<option value="YES">Ja</option>
								</select>
							</div>
						</div>

						{/* Minimum price. */}
						<div className="field">
							<label>Reservationspris</label>
							<div className="input">
								<input type="number" value={minimumPrice} onChange={minimumPriceChange} />
							</div>
						</div>

						{/* Attachments. */}
						<div className="field full">
							<label>Bilagor</label>

							<div className="meta-table">
								{metadata?.filter((m) => m[0].startsWith('_attachment_')).map((meta) => (
									<div className="meta-row" key={meta[0]}>
										<div className="input">
											<FileSelector
												path={`/attachments/${id}`}
												defaultFile={meta[1]}
												allowRemove={false}
												onChange={async (file) => {
													setMetadata(metadata.map((m) => (m[0] == meta[0] ? [m[0] + file.id, file.id] : m)));
												}}
											/>
										</div>
										<Icon
											onClick={() => {
												setMetadata(metadata.filter((m) => m != meta));
											}}
										>
											delete
										</Icon>
									</div>
								))}

								{(!metadata || metadata.filter((m) => m[0].startsWith('_attachment_')).length == 0) && (
									<div className="nometa">
										<h3>Inga bilagor tillagda</h3>
										<p>Lägg till bilagor som ska visas för besökaren.</p>
									</div>
								)}

								<div
									className="add-new-row"
									onClick={() => {
										setMetadata([...metadata, ['_attachment_', '']]);
									}}
								>
									<Icon>add</Icon>
									<span>Lägg till ny bilaga</span>
								</div>
							</div>


						</div>

						{/* Meta Description. */}
						<div className="field full">
							<label>Meta Beskrivning</label>
							<DescriptionInput 
								object={object} 
								defaultValue={object.metaDescription}
								onChange={(value) => setTemp((t) => ({ ...t, metaDescription: value }))} 
								edit={edit.metaDescription}
								allowAuto={true}
								toggleEdit={() => {
									setEdit((e) => ({ ...e, metaDescription: !e.metaDescription }))
								}}
							/>
						</div>

						{/* Description. */}
						<div className="field full">
							<label>Beskrivning</label>
							<DescriptionInput 
								object={object}
								defaultValue={object.description} 
								onChange={(value) => setTemp((t) => ({ ...t, description: value }))} 
								edit={edit.description}
								toggleEdit={() => {
									setEdit((e) => ({ ...e, description: !e.description }))
								}}
							/>
						</div>

						{/* Meta values. */}
						<div className="field full">
							<label>info</label>

							<div className="meta-table">
								{metadata
									?.filter((m) => !m[0].startsWith('_'))
									.map((meta) => (
										<div className="meta-row">
											<input type="text" value={meta[0]} onChange={(e) => setMetadata(metadata.map((m) => (m == meta ? { ...meta, 0: e.target.value } : m)))} />
											<input type="text" value={meta[1]} onChange={(e) => setMetadata(metadata.map((m) => (m == meta ? { ...meta, 1: e.target.value } : m)))} />
											<Icon
												onClick={() => {
													setMetadata(metadata.filter((m) => m != meta));
												}}
											>
												delete
											</Icon>
										</div>
									))}

								{(!metadata || metadata.filter((m) => !m[0].startsWith('_')).length == 0) && (
									<div className="nometa">
										<h3>Ingen metadata tillagd</h3>
										<p>Lägg till metadata för att ge besökaren info gällande objekten. Viss metadata används även för filtrering av objekten.</p>
									</div>
								)}

								<div
									className="add-new-row"
									onClick={() => {
										setMetadata([...metadata, ['', '']]);
									}}
								>
									<Icon>add</Icon>
									<span>Lägg till ny rad</span>
								</div>
							</div>
						</div>

						{/* Cost meta values. */}
						<div className="field full">
							<label>kostnader / avdrag</label>

							<div className="meta-table">
								{metadata
									?.filter((m) => m[0].startsWith('_cost_'))
									.map((meta) => (
										<div className="meta-row">
											<input type="text" value={meta[0].replace('_cost_', '')} onChange={(e) => setMetadata(metadata.map((m) => (m == meta ? { ...meta, 0: '_cost_' + e.target.value } : m)))} disabled={meta[0] === '_cost_Lösen av egendom'} />
											<input type="number" value={meta[1]} onChange={(e) => setMetadata(metadata.map((m) => (m == meta ? { ...meta, 1: e.target.value } : m)))} />
											<Icon
												onClick={() => {
													setMetadata(metadata.filter((m) => m != meta));
												}}
											>
												delete
											</Icon>
										</div>
									))}

								{(!metadata || metadata.filter((m) => m[0].startsWith('_cost_')).length == 0) && (
									<div className="nometa">
										<h3>Inga kostnader tillagda</h3>
										<p>Lägg till kostnader som ska dras av från säljarens utbetalning, dessa kostnader ska skrivas in som positiva värden.</p>
									</div>
								)}

								<div className="buttons" style={{
									display: 'flex',
									alignItems: 'center',
									gap: 20
								}}>
									<div
										className="add-new-row"
										onClick={() => {
											setMetadata([...metadata, ['_cost_', '']]);
										}}
									>
										<Icon>add</Icon>
										<span>Lägg till ny kostnad</span>
									</div>

									<div
										className="add-new-row"
										onClick={() => {
											setMetadata([...metadata, ['_cost_Lösen av egendom', '']]);
										}}
									>
										<Icon>add</Icon>
										<span>Lägg till lösen av egendom</span>
									</div>
								</div>
							</div>
						</div>

						{/* Keywords. */}
						<div className="field full">
							<label>sökord</label>

							<div className="keywords-table meta-table">
								{metadata
									?.filter((m) => m[0].startsWith('_search_keyword'))
									.map((meta) => (
										<div className="meta-row">
											<input type="string" value={meta[1]} onChange={(e) => setMetadata(metadata.map((m) => (m == meta ? { ...meta, 1: e.target.value } : m)))} />
											<Icon
												onClick={() => {
													setMetadata(metadata.filter((m) => m != meta));
												}}
											>
												delete
											</Icon>
										</div>
									))}

								{(!metadata || metadata.filter((m) => m[0].startsWith('_search_keyword')).length == 0) && (
									<div className="nometa">
										<h3>Inga sökord tillagda</h3>
										<p>Lägg till sökord som används av sökfunktionen på hemsidan.</p>
									</div>
								)}

								<div
									className="add-new-row"
									onClick={() => {
										setMetadata([...metadata, ['_search_keyword_' + (Math.random() * 100000).toFixed(0), '']]);
									}}
								>
									<Icon>add</Icon>
									<span>Lägg till nytt sökord</span>
								</div>
							</div>
						</div>
					</div>

					<div className="spacer l"></div>

					<BiddingPanel object={object} />

					<div className="spacer l"></div>
				</div>
			</main>
		</Style>
	);
}

const Style = styled(Page)`
	.meta-table {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		.nometa {
			background: #eee;
			padding: 1.25rem;
			border-radius: 0.35em;
			text-align: center;
			margin-bottom: 1rem;

			h3 {
				font-size: 1em;
				color: gray;
			}

			p {
				color: gray;
				width: 20em;
				margin: auto;
				margin-top: 0.75rem;
				font-size: 0.7em;
			}
		}

		.meta-row {
			display: grid;
			grid-template-columns: 1fr 1fr 1.5rem;
			align-items: center;
			gap: 1rem;
			margin-bottom: 1rem;

			.icon {
				font-size: 1.5rem;
			}
		}

		.add-new-row {
			cursor: pointer;
			display: flex;
			align-items: center;

			.icon {
				font-size: 1.5rem;
			}

			span {
				margin-left: 0.5rem;
				color: var(--color-link);
			}
		}

		&.keywords-table {
			.meta-row {
				grid-template-columns: 1fr 1.5rem;
			}
		}
	}

	main {
		grid-column: span 3;

		.contained {
			width: 75%;
			margin: auto;
		}

		label {
			margin-bottom: 0.5rem;
			text-transform: uppercase;
		}

		.title-row {
			display: flex;
			align-items: flex-start;

			.title {
				padding: 0;
				background: none;
				border: none;
				resize: none;
				width: 75%;
				font-size: 2em;

				&:disabled {
					color: black;
				}

				input {
					border-radius: 0;
				}
			}

			.icon {
				margin-left: 2rem;
				font-size: 1.5rem;
			}
		}

		.full-title {
			margin-top: 0.5rem;
		}

		.button-row {
			margin-top: 3rem;
		}

		.fields-grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 2rem;
			margin-top: 4rem;

			.field {
				display: flex;
				flex-direction: column;

				&.full {
					grid-column: span 2;
				}

				.input {
					width: 100%;
					display: flex;

					select {
						width: 100%;
					}

					& > input {
						width: 100%;
					}
				}
			}
		}

		.bidding-panel {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 3rem;

			.leading-bid,
			.leading-max-bid {
				display: flex;
				flex-direction: column;
				align-items: stretch;

				.value {
					padding: 1rem;
					background: white;
					border-radius: 0.35rem;
					font-size: 1.5rem;
				}
			}

			.bidding-table {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				grid-column: span 2;

				.bids {
					padding: 1rem;
					background: white;
					border-radius: 0.35rem;
				}
			}
		}
	}

	.add-new {
		background: red;
		background: #f5f5f5;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 10em !important;

		.icon {
			font-size: 2em;
		}
	}

	.object-info {
		.image {
			width: 100%;
			position: relative;
			cursor: pointer;
			min-height: 16em;

			.icon {
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 4em;
				color: white;
			}

			img {
				border-radius: 1em;
				width: 100%;
				transition: filter 300ms;
				// line-height: 0 !important;
			}

			&:hover {
				.icon {
					opacity: 1;
				}

				img {
					filter: brightness(0.5);
				}
			}
		}

		.title {
			font-size: 2em;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}

	.mobile & {
		main {
			grid-column: span 1;

			.contained {
				width: 100%;

				.row {
					flex-direction: column;

					&.title-row {
						flex-direction: row;
					}

					& > * {
						margin-bottom: 0.5rem;
					}
				}

				.fields-grid {
					gap: 1rem;

					& > * {
						grid-column: span 2;
					}

					.meta-table {
						.meta-row {
							grid-template-columns: 1fr 1.5rem;
							padding: 0.5rem;
							background-color: #eee;
							margin-bottom: 0.5rem;
							border-radius: 0.35rem;

							input {
								grid-column: 1 / 2;
							}
						}
					}
				}
			}
		}
	}
`;
